import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { userLocationState } from '../../../entities/userPosition/model/locationState'
import { logWithColor } from '../../../shared/utils/debugUtils'

const LocationWatcher = () => {
	const setUserLocation = useSetRecoilState(userLocationState)

	useEffect(() => {
		if (navigator.geolocation) {
			const watchId = navigator.geolocation.watchPosition(
				(position) => {
					setUserLocation({
						isReady: true,
						lat: position.coords.latitude,
						lon: position.coords.longitude
					})
					logWithColor(
						`[GEO WATCHER] coords: [${[position.coords.latitude, position.coords.longitude]}]`,
						'#007BFF'
					)
				},
				(error) => {
					console.error('[GEO WATCHER]', error)
				},
				{
					enableHighAccuracy: true, // Повышенная точность
					timeout: 10000, // Таймаут 10 секунд
					maximumAge: 1000 // Не использовать кэшированные данные
				}
			)

			return () => {
				navigator.geolocation.clearWatch(watchId)
			}
		} else {
			console.error(
				'[GEO WATCHER] Geolocation is not supported by this browser'
			)
		}
	}, [])

	return null
}

export default LocationWatcher