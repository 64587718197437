import React, { useEffect, useState } from 'react'
import {
	YMap,
	YMapComponentsProvider,
	YMapDefaultSchemeLayer,
	YMapDefaultFeaturesLayer,
	YMapMarker,
	YMapListener,
	YMapFeature
} from 'ymap3-components'
import styled from 'styled-components'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentTask } from '../../../../entities/currentTask/model/currentTask'
import targetIcon from '../../../assets/icons/targetpoint.svg'
import targetPartnerIcon from '../../../assets/icons/targetPartnerIcon.svg'
import userIcon from '../../../assets/icons/usermarker.png'
import { userLocationState } from '../../../../entities/userPosition/model/locationState'
import { editState } from '../../../../entities/editMode/model/editState'
import {
	createCirclePolygon,
	isPointInPolygon,
	moveMarkerAboveUser,
	smoothTransition
} from '../../../utils/mapUtils'
import { tgState } from '../../../../entities/tg/model/tgState'
import { motion } from 'framer-motion'
import { markerVariants } from '../../../assets/animations'
import { getLangForMapInit } from '../../../utils/initUtils'
import { logWithColor } from '../../../utils/debugUtils'
import { mapLocationState } from '../../../../entities/mapLocation/model/mapLocationState'
import {config} from "../../../../config";

const YandexMap = ({ map, setMap }) => {
	const taskMarker = useRecoilValue(currentTask)
	const userMarker = useRecoilValue(userLocationState)

	const [location, setLocation] = useRecoilState(mapLocationState)
	const [newTask, setNewTask] = useState([])

	const [circleCoordinates, setCircleCoordinates] = useState([])
	const [edit, setEdit] = useRecoilState(editState)

	const [isInPolygon, setIsInPolygon] = useState(true)
	const tg = useRecoilValue(tgState)

	const onUpdate = React.useCallback(({ location, mapInAction }) => {
		if (!mapInAction) {
			setLocation({
				center: location.center,
				zoom: location.zoom
			})
		}
	}, [])

	const handleMarkerDragEnd = (e) => {
		const [newLongitude, newLatitude] = e
		setNewTask([newLongitude, newLatitude])
		const isValid = isPointInPolygon(e, circleCoordinates)
		if (isValid) tg['tg'].HapticFeedback.notificationOccurred('error')
		setEdit({ ...edit, isInvalid: isValid, coords: e })
		setIsInPolygon(isValid)
	}

	useEffect(() => {
		if (map) {
			const events = ['touchstart', 'touchmove', 'touchend']
			const mapElement = document.getElementById('map')
			events.forEach((event) => {
				mapElement.addEventListener(event, (e) => {
					e.preventDefault()
				})
			})

			logWithColor(`[MAP] - INIT`, '#47d538')
			logWithColor(`[LOCALE] - ${tg.language}`, '#007BFF')
		}
	}, [map])

	useEffect(() => {
		if (userMarker.isReady) {
			smoothTransition(
				location.center,
				location.zoom,
				[userMarker.lon, userMarker.lat],
				16,
				800,
				(updatedLocation) => {
					setLocation(updatedLocation)
				},
				() => {}
			)
		}
	}, [userMarker.isReady])
	useEffect(() => {
		if (edit.isEdit) {
			setIsInPolygon(true)

			smoothTransition(
				location.center,
				location.zoom,
				[userMarker.lon, userMarker.lat],
				13.6,
				800,
				(updatedLocation) => {
					setLocation(updatedLocation)
				},
				() => {
					// Выполняется после завершения анимации
					setNewTask(
						moveMarkerAboveUser(userMarker.lat, userMarker.lon, 100)
					)

					if (userMarker.lat && userMarker.lon && edit) {
						let radius = 0
						const maxRadius = 1 // Максимальный радиус круга
						const step = 0.017 // Шаг изменения радиуса
						const duration = 600 // Длительность анимации в миллисекундах
						const intervalTime = duration / (maxRadius / step) // Время одного шага

						const interval = setInterval(() => {
							radius += step
							if (radius >= maxRadius) {
								clearInterval(interval)
							}

							tg['tg'].HapticFeedback.impactOccurred('soft')
							tg['tg'].HapticFeedback.selectionChanged()

							setCircleCoordinates(
								createCirclePolygon(
									userMarker.lon,
									userMarker.lat,
									radius
								)
							)
						}, intervalTime)

						return () => clearInterval(interval) // Очистка интервала при размонтировании компонента
					}
				}
			)
		} else {
			setCircleCoordinates([])
			setNewTask([])
		}
	}, [edit.isEdit])

	useEffect(()=>{
		if (edit && circleCoordinates.length !== 0) {
			console.log(userMarker.lon)
			console.log(userMarker.lat)
			setCircleCoordinates(
				createCirclePolygon(
					userMarker.lon,
					userMarker.lat,
					1
				)
			)
		}
	},[userMarker.lon, userMarker.lat])

	const checkMarkerInPolygon = () => {
		if (isPointInPolygon(newTask, circleCoordinates)) {
			tg['tg'].HapticFeedback.notificationOccurred('error')
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			checkMarkerInPolygon()
		}, 2000)

		return () => clearInterval(interval)
	}, [newTask, circleCoordinates])

	return (
		<Wrapper id="map">
			<YMapComponentsProvider
				apiKey={config.mapKey}
				lang={getLangForMapInit(tg.language)}
			>
				<YMap
					key="map"
					ref={(ymap) => {
						setMap(ymap)
					}}
					location={location}
					mode="vector"
					// theme="dark"
					theme="light"
					zoomRange={{ min: 3, max: 21 }}
					showScaleInCopyrights={true}
				>
					<YMapDefaultSchemeLayer />
					<YMapDefaultFeaturesLayer />
					<YMapListener onUpdate={onUpdate} />
					{edit.isEdit && (
						<>
							<YMapFeature
								geometry={{
									type: 'Polygon',
									coordinates: [circleCoordinates]
								}}
								style={{
									stroke: [{ color: '#FC7777', width: 2 }],
									fill: 'rgba(255, 136, 136, 0.2)'
								}}
							/>

							<YMapMarker
								coordinates={newTask}
								draggable={true}
								onDragEnd={handleMarkerDragEnd}
							>
								<ImgWrapper className={isInPolygon && 'big'}>
									<TargetMarker
										src={targetIcon}
										alt="marker"
										initial="hidden"
										animate="visible"
										variants={markerVariants}
									/>
								</ImgWrapper>
							</YMapMarker>
						</>
					)}
					{taskMarker && (
						<YMapMarker
							coordinates={[
								taskMarker.place.lon,
								taskMarker.place.lat
							]}
						>
							<ImgWrapper>
								<TargetMarker
									src={taskMarker.partnerTaskId ? targetPartnerIcon : targetIcon}
									alt="marker"
									initial="hidden"
									animate="visible"
									variants={markerVariants}
								/>
							</ImgWrapper>
						</YMapMarker>
					)}
					{userMarker.isReady && (
						<YMapMarker
							coordinates={[userMarker.lon, userMarker.lat]}
						>
							<UserMarker src={userIcon} />
						</YMapMarker>
					)}
				</YMap>
			</YMapComponentsProvider>
		</Wrapper>
	)
}

const ImgWrapper = styled.div`
	position: absolute;
	transform: translate(-50%, -100%);
	&.big {
		transform: translate(-50%, -100%) scale(1.35);
	}
`

const TargetMarker = styled(motion.img)`
	width: 36px;
`

const UserMarker = styled.img`
	transform: translate(-50%, -50%);
	width: 28px;
`

const Wrapper = styled.div`
	height: calc(100vh - 145px);
	width: 100vw;
	position: absolute;
	top: 0;
	z-index: 1;
`

export default YandexMap