import styled from 'styled-components'
import { Navbar } from '../../../shared/ui/navbar'
import { NavbarUpper } from '../../../shared/ui/navbar-upper'

export const Navigations = () => {
	return (
		<Wrapper>
			<NavbarUpper />
			<Navbar />
		</Wrapper>
	)
}
const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	z-index: 2;
`