export function getLangForMapInit(ietfTag) {
	const langMap = {
		en: 'en_US',
		'en-US': 'en_US',
		'en-GB': 'en_US',
		ru: 'ru_RU',
		'ru-RU': 'ru_RU',
		uk: 'uk_UA',
		'uk-UA': 'uk_UA'
	}

	// Извлекаем основную часть тега (первые два символа)
	const baseTag = ietfTag.split('-')[0]

	// Возвращаем соответствующее значение из словаря или дефолтное значение
	return langMap[ietfTag] || langMap[baseTag] || 'en_US'
}