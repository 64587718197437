export const markerVariants = {
	hidden: { opacity: 1, scale: 1 },
	visible: {
		opacity: 1,
		scale: [1, 1.5, 1],
		transition: {
			opacity: { duration: 0.3 },
			scale: { duration: 0.4, times: [0, 0.7, 1] }
		}
	}
}
export const pageVariants = {
	initial: {
		opacity: 0
	},
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	}
}

export const pageTransition = {
	type: 'tween',
	ease: 'anticipate',
	duration: 0.33
}