import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Routes } from './routes'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { tgState } from '../../../entities/tg/model/tgState'

export const Navbar = () => {
	const tg = useRecoilValue(tgState)

	const handleMenuClick = () => {
		tg['tg'].HapticFeedback.selectionChanged()
	}

	const menuItems = useMemo(() => {
		return Routes.map((item) => (
			<MenuItem
				key={item.id}
				to={item.path}
				onClick={() => handleMenuClick()}
			>
				<img src={item.icon} alt={item.text} />
				<span>{item.text}</span>
			</MenuItem>
		))
	}, [Routes])

	return <Wrapper>{menuItems}</Wrapper>
}

const Wrapper = styled.div`
	width: 100%;
	background: #212121;
	padding-bottom: 34px;
	display: flex;
	justify-content: space-around;
	border-top: 1px solid rgba(255, 255, 255, 5%);
`

const MenuItem = styled(Link)`
	width: 100%;
	padding-top: 8px;
	padding-bottom: 4px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4px;
	text-decoration: none;
	color: #fff;
	img {
		width: 28px;
	}
	span {
		font-size: 10px;
		font-weight: 500;
	}
`