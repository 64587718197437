import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

export const Button = ({
	children,
	onClick,
	isDisabled = false,
	isLoading = false,
	backgroundColor = '#007BFF',
	color = '#FFF',
	padding = '14px 0',
	borderRadius = '10px',
	fontSize = '17px',
	fontWeight = '500',
	width = '100%',
	disabledBackgroundColor = backgroundColor,
	disabledColor = color
}) => {
	const buttonRef = useRef(null)

	useEffect(() => {
		const button = buttonRef.current

		const handleTouchStart = () => {
			button.classList.add('hover')
		}

		const handleTouchEnd = () => {
			button.classList.remove('hover')
		}

		button.addEventListener('touchstart', handleTouchStart)
		button.addEventListener('touchend', handleTouchEnd)
		button.addEventListener('touchcancel', handleTouchEnd)

		return () => {
			button.removeEventListener('touchstart', handleTouchStart)
			button.removeEventListener('touchend', handleTouchEnd)
			button.removeEventListener('touchcancel', handleTouchEnd)
		}
	}, [])

	const handleClick = (event) => {
		if (isLoading || isDisabled) {
			return
		}
		const button = event.currentTarget
		button.classList.add('active')
		setTimeout(() => {
			button.classList.remove('active')
		}, 150) // Время должно соответствовать длительности анимации
		onClick()
	}

	return (
		<StyledButton
			ref={buttonRef}
			onClick={handleClick}
			disabled={isLoading || isDisabled}
			$backgroundColor={backgroundColor}
			$color={color}
			$padding={padding}
			$borderRadius={borderRadius}
			$fontSize={fontSize}
			$fontWeight={fontWeight}
			$width={width}
			$disabledBackgroundColor={disabledBackgroundColor}
			$disabledColor={disabledColor}
		>
			{isLoading ? <Loader /> : children}
		</StyledButton>
	)
}

const StyledButton = styled.button`
	background-color: ${({
		$backgroundColor,
		disabled,
		$disabledBackgroundColor
	}) => (disabled ? $disabledBackgroundColor : $backgroundColor)};
	color: ${({ $color, disabled, $disabledColor }) =>
		disabled ? $disabledColor : $color};
	padding: ${({ $padding }) => $padding};
	border: none;
	border-radius: ${({ $borderRadius }) => $borderRadius};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	font-size: ${({ $fontSize }) => $fontSize};
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: ${({ $fontWeight }) => $fontWeight};
	width: ${({ $width }) => $width};
	-webkit-tap-highlight-color: transparent;
	transition: all 0.5s ease;
	position: relative;
	overflow: hidden;
	box-shadow: ${({ disabled }) =>
		disabled
			? 'inset 0 0 3px rgb(0 0 0 / 15%)'
			: '0 0 3px rgb(0 0 0 / 15%)'};

	&.hover {
		opacity: 0.8;
	}

	&:focus {
		outline: none;
	}

	&.active {
		animation: clickAnimation 0.15s ease;
	}

	@keyframes clickAnimation {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(0.95);
		}
		100% {
			transform: scale(1);
		}
	}
`

const Loader = styled.div`
	border: 2px solid rgb(255, 255, 255, 0.5);
	border-top: 2px solid transparent;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`