import { useCallback } from 'react'
import { request } from '../../api/request/request'

export const useApi = () => {
	const sendRequest = useCallback(
		async (method, data = {}, requestMethod = 'POST') => {
			return await request(method, data, requestMethod)
		},
		[]
	)

	return { sendRequest }
}