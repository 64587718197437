import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {motion} from "framer-motion";
import {useRecoilState} from "recoil";
import {leaderState} from "../../entities/leaderboards/model/leaderState";
import {useApi} from "../../shared/hooks/api/useApi";

export const LeaderBoard = () => {
    const [leader, setLeader] = useRecoilState(leaderState);
    const { sendRequest } = useApi();
    const [top100, setTop100] = useState([]);

    useEffect(()=>{
        const fetch = async () => {
            try {
                const response = await sendRequest('user/user_leaderboard');
                setTop100(response.data.topUsers.slice(3));
                console.log(response.data.topUsers);
            } catch (e) {
                console.log(e);
            }
        }
        fetch();
    },[]);
    return (
        <Wrapper>
            <ModalContainer>
                <Box>
                    <FlexClmn style={{gap: '24px'}}>
                        <FlexRow style={{justifyContent: 'space-between'}}>
                            <div  style={{color: '#FFF', fontWeight: '500', fontSize: '17'}}>
                                Leaderboard
                            </div>
                        </FlexRow>
                        <FlexRow style={{justifyContent: 'space-around'}}>
                            <FlexClmn style={{alignItems: 'center'}}>
                                <TopBadge className={'second'}>
                                    <p>{leader.top3[1].firstName.split('')[0].toUpperCase()}</p>
                                    <span className={'second'}>2</span>
                                </TopBadge>
                                <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.top3[1].firstName}</p>
                                <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.top3[1].totalScore}</p>
                            </FlexClmn>
                            <FlexClmn style={{alignItems: 'center'}}>
                                <TopBadge className={'first'}>
                                    <p>{leader.top3[0].firstName.split('')[0].toUpperCase()}</p>
                                    <span className={'first'}>1</span>
                                </TopBadge>
                                <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.top3[0].firstName}</p>
                                <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.top3[0].totalScore}</p>
                            </FlexClmn>
                            <FlexClmn style={{alignItems: 'center'}}>
                                <TopBadge className={'third'}>
                                    <p>{leader.top3[2].firstName.split('')[0].toUpperCase()}</p>
                                    <span className={'third'}>3</span>
                                </TopBadge>
                                <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.top3[2].firstName}</p>
                                <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.top3[2].totalScore}</p>
                            </FlexClmn>
                        </FlexRow>
                        {(Number(leader.userPosition.position) > 100) &&
                            <FlexRow style={{gap: '12px'}}>
                                <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px',width: '28px', textAlign: 'center'}}>{leader.userPosition.position}</p>
                                <TopBadge className={'default'}>
                                    <p>{leader.userPosition.firstName.split('')[0].toUpperCase()}</p>
                                </TopBadge>
                                <FlexClmn style={{width: 'fit-content'}}>
                                    <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.userPosition.firstName}</p>
                                    <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.userPosition.totalScore}</p>
                                </FlexClmn>
                            </FlexRow>
                        }
                        {top100 && top100.map((item, index) => (
                            <FlexRow style={{gap: '12px'}} key={index}>
                                <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px',  width: '28px', textAlign: 'center'}}>{index + 4}</p>
                                <TopBadge className={'default'}>
                                    <p>{item.firstName.split('')[0].toUpperCase()}</p>
                                </TopBadge>
                                <FlexClmn style={{width: 'fit-content'}}>
                                    <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{item.firstName}</p>
                                    <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{item.totalScore}</p>
                                </FlexClmn>
                            </FlexRow>
                        )) }

                    </FlexClmn>
                </Box>
            </ModalContainer>
        </Wrapper>
    );
};

export const TopBadge = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 32px;
    font-weight: 500;
    position: absolute;
    color: #000;
  }
  &.default {
    width: 48px;
    height: 48px;
    border: 4px solid #FFF;
  }
  &.first {
    margin-bottom: 12px;
    border: 4px solid #FEC44B;
    width: 68px;
    height: 68px;
    color: #AE8125;
  }
  &.second {
    margin-bottom: 16px;
    border: 4px solid #ACACAC;
    color: #6A6A6A;
  }
  &.third {
    margin-bottom: 16px;
    border: 4px solid #E18327;
    color: #854B12;
  }
  span {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    &.first {
      background:#FEC44B;
    }
    &.second {
      background:#ACACAC;
    }
    &.third {
      background:#E18327;
    }
  }
`
const BadgeWhite = styled.div`
  position: absolute;
  border-radius: 100%;
  background: #fff;
  width: 65%;
  height: 65%;
`
const BadgeText = styled.p`
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  &.bronze {
    background: linear-gradient(90deg, #AAA87D 0%, #B57B0E 31%, #A08042 76%, #BCAC66 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`
const BadgeWrapper = styled.div`
  width: 56px;
  height: 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BadgeWrapperSmall = styled(BadgeWrapper)`
  width: 36px;
  height: 36px;
`
const Badge = styled.img`
	width: 100%;
	height: 100%;
`
const BadgeInner = styled.img`
	position: absolute;
	width: 38px;
	height: 38px;
`
export const Box = styled.div`
	background: #1C1C1E;
	border-radius: 20px;
	padding: 20px 16px;
	color: #fff;
	font-size: 17px;
	width: 100%;
`
const FlexClmn = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`
const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 998;
  overscroll-behavior: none;
  background: #000000;
  padding: 16px;
`

const Wrapper = styled(motion.div)`
  width: 100%;
  height: calc(100vh + 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  background: transparent;
  z-index: 999;
`