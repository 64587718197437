import { atom } from 'recoil'

export const tgState = atom({
	key: 'tgState',
	default: {
		initData: null,
		isReady: false,
		tg: null,
		hash: null,
		minHeight: null
	}
})