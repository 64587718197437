import { logWithColor } from '../../utils/debugUtils'
import {config} from "../../../config";

const defaultHeaders = {
	'Content-Type': 'application/json'
}

export const request = async (method, data = {}, requestMethod = 'POST') => {
	const authData = window.Telegram?.WebApp?.initData || ''
	const host = window.location.host || ''

	const body = {
		auth: authData,
		host: host,
		...data
	}

	const bodyJson = JSON.stringify(body);
	const apiHost = config.apiHost;
	const url = `${apiHost}/${method}/`

	const fetchOptions = {
		method: requestMethod,
		headers: {
			...defaultHeaders
		},
		body: requestMethod !== 'GET' ? bodyJson : undefined,
		mode: 'cors',
		credentials: 'include'
	}

	logWithColor(`[REQUEST] - ${url}`, '#ce9f21')

	const response = await fetch(url, fetchOptions)

	if (!response.ok) {
		throw new Error('Network response was not ok')
	}

	try {
		return await response.json()
	} catch (error) {
		console.log('Error parsing JSON:', error)
	}
}