import { createGlobalStyle } from 'styled-components'
import { Fonts } from '../../shared/assets/fonts/SF'

export const GlobalStyle = createGlobalStyle`
  ${Fonts}
  * {
    font-family: 'SF', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: visible;
    touch-action: none !important;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  .ymaps3x0--map-copyrights_bottom {
    bottom: 14px !important;
  }
`