// CREATE RESTRICTED POLYGON
export const createCirclePolygon = (lon, lat, r = 0.5, n = 1000) => {
	const points = []
	let k = Math.cos((lat * Math.PI) / 180)
	for (let i = 0; i < n; i++) {
		points.push([
			lon + (r / 111 / k) * Math.cos(((2 * Math.PI) / n) * i),
			lat + (r / 111) * Math.sin(((2 * Math.PI) / n) * i)
		])
	}
	return points
}

// IS MARKER IN POLYGON
export const isPointInPolygon = (point, polygon) => {
	const [x, y] = point
	let isInside = false

	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		const [xi, yi] = polygon[i]
		const [xj, yj] = polygon[j]

		const intersect =
			yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
		if (intersect) isInside = !isInside
	}

	return isInside
}

// SET UP MARKER ABOVE USER LOCATION
export const moveMarkerAboveUser = (
	userLatitude,
	userLongitude,
	distanceInMeters
) => {
	const earthRadius = 6371000 // Радиус Земли в метрах

	// Смещение по широте (в градусах)
	const latOffset = (distanceInMeters / earthRadius) * (180 / Math.PI)

	// Новые координаты метки
	const newLatitude = userLatitude + latOffset
	const newLongitude = userLongitude

	return [newLongitude, newLatitude]
}

// SETLOCATION SMOOTH ANIMATION
export function smoothTransition(
	currentCenter,
	currentZoom,
	newCenter,
	newZoom,
	duration,
	onUpdate,
	onComplete
) {
	const startTime = performance.now()
	const startLon = currentCenter[0]
	const startLat = currentCenter[1]
	const startZoom = currentZoom
	const deltaLon = newCenter[0] - startLon
	const deltaLat = newCenter[1] - startLat
	const deltaZoom = newZoom - startZoom

	function animate() {
		const currentTime = performance.now()
		const elapsed = currentTime - startTime
		const progress = Math.min(elapsed / duration, 1)

		const currentLon = startLon + deltaLon * progress
		const currentLat = startLat + deltaLat * progress
		const currentZoom = startZoom + deltaZoom * progress

		onUpdate({ center: [currentLon, currentLat], zoom: currentZoom })

		if (progress < 1) {
			requestAnimationFrame(animate)
		} else {
			if (onComplete) {
				onComplete()
			}
		}
	}

	requestAnimationFrame(animate)
}

// FROM 2 COORDS TO METRS
export function haversineDistance(lat1, lon1, lat2, lon2) {
	const R = 6371000 // Радиус Земли в метрах
	const toRadians = (degree) => degree * (Math.PI / 180)

	const dLat = toRadians(lat2 - lat1)
	const dLon = toRadians(lon2 - lon1)
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(toRadians(lat1)) *
			Math.cos(toRadians(lat2)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	return R * c
}

// CAN I CHECK IN
export function isWithin100Meters(markerLat, markerLon, userLat, userLon) {
	const distance = haversineDistance(markerLat, markerLon, userLat, userLon)
	return distance <= 100
}