import { GlobalStyle } from './styles/globalStyles'
import { Router } from './routing'
import { BrowserRouter } from 'react-router-dom'
import { useState, useEffect } from 'react'
import YandexMap from '../shared/ui/map/ui/YandexMap'
import { useInit } from './hooks/useInit'
import LocationWatcher from '../features/locationWatcher/ui/LocationWatcher'
import PageLoader from '../features/pageLoader/ui/PageLoader'
import { Navigations } from '../widgets/Navigations'
import { Balance } from '../widgets/Balance'
import styled from 'styled-components'

export const App = () => {
	const isInitialized = useInit()
	const [map, setMap] = useState()
	const [showLoader, setShowLoader] = useState(true)

	useEffect(() => {
		const minLoadingTime = 1000

		if (isInitialized && map) {
			const timer = setTimeout(() => {
				setShowLoader(false)
			}, minLoadingTime)

			return () => clearTimeout(timer)
		}
	}, [isInitialized, map])

	return (
		<BrowserRouter>
			{isInitialized && (
				<ModalContainer>
					<Wrapper>
						<Balance />
						<YandexMap map={map} setMap={setMap} />
						{map && <LocationWatcher />}
						<Navigations />
					</Wrapper>
				</ModalContainer>
			)}
			<GlobalStyle />
			{showLoader ? <PageLoader /> : <Router />}
		</BrowserRouter>
	)
}

const ModalContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 996;
	background: #212121;
	overscroll-behavior: none;
`
const Wrapper = styled.div`
	width: 100%;
	height: calc(100% + 1px);
	position: relative;
	z-index: 997;
`