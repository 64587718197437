import React, { useEffect, useState} from 'react';
import styled from "styled-components";
import { motion} from "framer-motion";
import { MainText, WolfBG } from "../earn/ui";
import bgWolf from "../../shared/assets/bg/bgWolf.png";
import {useApi} from "../../shared/hooks/api/useApi";
import {useRecoilState, useRecoilValue} from "recoil";
import {tgState} from "../../entities/tg/model/tgState";
import {useNavigate, useParams} from "react-router-dom";
import {Box, TopBadge} from "../leaderboard/ui";
import {Button} from "../../shared/ui/button";
import {userClubState} from "../../entities/userClub/model/userClubState";
import confetti from "canvas-confetti";

export const ClubInfo = () => {
    const { id } = useParams();
    const [club, setClub] = useState([]);
    const [users, setUsers] = useState([]);
    const [joinLoad, setJoinLoad] = useState(false);
    const [leaveLoad, setLeaveLoad] = useState(false);
    const [userClub, setUserClub] = useRecoilState(userClubState);
    const navigate = useNavigate();

    const {sendRequest} = useApi();
    const tg = useRecoilValue(tgState);

    useEffect(()=>{
        const fetch = async () => {
            try {
                const response = await sendRequest('club/get_club_info', {clubId: Number(id)});
                setClub(response.data);
                setUsers(response.data.users.slice(3));
            } catch (e) {
                console.log(e);
            }
        }
        fetch();
    },[]);

    const handleJoin = async () => {
        try {
            setJoinLoad(true);
            const response = await sendRequest('club/join_club', {
                clubId: Number(id)
            })
            if (response.success) {
                setUserClub(response.data);
                const canvas = document.getElementById('confetti-canvas')
                confetti.create(canvas, { resize: true })({
                    particleCount: 200,  // Увеличенное количество частиц для большего эффекта
                    spread: 360,         // Распределение частиц на 360 градусов для взрыва
                    origin: { x: 0.9, y: 0.1 }, // Координаты правого верхнего угла
                    scalar: 0.75
                });



                navigate('/account');
                tg['tg'].HapticFeedback.notificationOccurred('success')
            } else {
                tg.tg.showAlert('Error has occurred. Please try again later.')
                tg['tg'].HapticFeedback.notificationOccurred('error')
            }
        } catch (e) {
            console.log(e)
        } finally {
            setJoinLoad(false)
        }
    }
    const handleLeave = async () => {
        try {
            setLeaveLoad(true);
            const response = await sendRequest('club/leave_club', {
                clubId: Number(id)
            })
            if (response.success) {
                setUserClub(null);
                navigate('/clubs');
                tg['tg'].HapticFeedback.notificationOccurred('success')
            } else {
                tg.tg.showAlert('Error has occurred. Please try again later.')
                tg['tg'].HapticFeedback.notificationOccurred('error')
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLeaveLoad(false)
        }
    }

    const handleInvite = () => {
        const userId = tg['initData'].user['id']
        const text = `TON Walk. Check in to cash out. Join and walk with me.`
        tg.tg.openTelegramLink(
            `https://t.me/share/url?url=https://t.me/tonwalk_bot?start=${userId}${club.refID}&text=${text}`
        )
    }
    return (
        <ModalContainer>
            <Wrapper>
                <WolfBG src={bgWolf} alt="bgWolf" />
                <BadgeWrapper>
                    <Badge src="/assets/icon/badgeBronze.svg" alt="badge"/>
                    <BadgeInner src="/assets/icon/wolf.png" alt="badge inner"/>
                </BadgeWrapper>
                <MainText>{club?.name}</MainText>
                <ActionPanel>
                    <FlexClmn style={{gap: '24px'}}>
                        <FlexRow>
                            <Block>
                                <SmallText>
                                    Earned total
                                </SmallText>
                                <BigText>
                                    {club?.totalScore || 0}
                                </BigText>
                            </Block>
                            <Block className={'last'}>
                                <SmallText>
                                    Walkers
                                </SmallText>
                                <BigText>
                                    {club?.users?.length || 0}
                                </BigText>
                            </Block>
                        </FlexRow>
                            {!userClub ?
                            <Button width={'100%'} onClick={() => handleJoin()} isLoading={joinLoad}>
                                Join club
                            </Button>
                                :
                            <FlexClmn style={{gap: '16px'}}>
                                <Button
                                    onClick={handleInvite}
                                    fontWeight={500}
                                    width={'100%'} onClick={() => handleInvite()} isLoading={joinLoad}>
                                    Invite friend
                                </Button>
                                <Button
                                    backgroundColor={'#2F2F35'}
                                    fontWeight={500}
                                    width={'100%'} onClick={() => handleLeave()} isLoading={leaveLoad}>
                                    Leave club
                                </Button>
                            </FlexClmn>
                            }
                    </FlexClmn>
                </ActionPanel>
                <Box>
                    <FlexClmn style={{gap: '24px'}}>
                        <FlexRow style={{justifyContent: 'space-between'}}>
                            <div  style={{color: '#FFF', fontWeight: '500', fontSize: '17'}}>
                                Club leaderboard
                            </div>
                        </FlexRow>
                        {club.users &&
                            <FlexRow style={{justifyContent: 'space-around'}}>
                                {club.users[1] &&
                                    <FlexClmn style={{alignItems: 'center'}}>
                                        <TopBadge className={'second'}>
                                            <p>{club?.users[1]?.firstName.split('')[0].toUpperCase()}</p>
                                            <span className={'second'}>2</span>
                                        </TopBadge>
                                        <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{club?.users[1]?.firstName}</p>
                                        <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{club?.users[1]?.totalScore}</p>
                                    </FlexClmn>
                                }
                                {club.users[0] &&
                                    <FlexClmn style={{alignItems: 'center'}}>
                                        <TopBadge className={'first'}>
                                            <p>{club?.users[0]?.firstName.split('')[0].toUpperCase()}</p>
                                            <span className={'first'}>1</span>
                                        </TopBadge>
                                        <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{club?.users[0]?.firstName}</p>
                                        <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{club?.users[0]?.totalScore}</p>
                                    </FlexClmn>
                                }
                                {club.users[2] &&
                                    <FlexClmn style={{alignItems: 'center'}}>
                                        <TopBadge className={'third'}>
                                            <p>{club?.users[2]?.firstName.split('')[0].toUpperCase()}</p>
                                            <span className={'third'}>3</span>
                                        </TopBadge>
                                        <p style={{
                                            fontSize: '17px',
                                            color: '#FFF',
                                            fontWeight: '400',
                                            lineHeight: '22px'
                                        }}>{club?.users[2]?.firstName}</p>
                                        <p style={{
                                            fontSize: '15px',
                                            color: '#C9CFD5',
                                            fontWeight: '400',
                                            lineHeight: '22px'
                                        }}>{club?.users[2]?.totalScore}</p>
                                    </FlexClmn>
                                }
                        </FlexRow>
                        }
                        {/*{(Number(leader.userPosition.position) > 100) &&*/}
                        {/*    <FlexRow style={{gap: '12px'}}>*/}
                        {/*        <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px',width: '28px', textAlign: 'center'}}>{leader.userPosition.position}</p>*/}
                        {/*        <TopBadge className={'default'}>*/}
                        {/*            <p>{leader.userPosition.firstName.split('')[0].toUpperCase()}</p>*/}
                        {/*        </TopBadge>*/}
                        {/*        <FlexClmn style={{width: 'fit-content'}}>*/}
                        {/*            <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.userPosition.firstName}</p>*/}
                        {/*            <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.userPosition.totalScore}</p>*/}
                        {/*        </FlexClmn>*/}
                        {/*    </FlexRow>*/}
                        {/*}*/}
                        {users && users.map((item, index) => (
                            <FlexRow style={{gap: '12px'}} key={index}>
                                <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px',  width: '28px', textAlign: 'center'}}>{index + 4}</p>
                                <TopBadge className={'default'}>
                                    <p>{item.firstName.split('')[0]?.toUpperCase()}</p>
                                </TopBadge>
                                <FlexClmn style={{width: 'fit-content'}}>
                                    <p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{item.firstName}</p>
                                    <p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{item.totalScore}</p>
                                </FlexClmn>
                            </FlexRow>
                        )) }
                    </FlexClmn>
                </Box>
            </Wrapper>
        </ModalContainer>
    );
};
const SmallText = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(235,235,245, 0.6);
`
const BigText = styled.p`
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
`
const Block = styled.div`
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0;
  &.last {
    border-left: 1px solid rgba(235,235,245, 0.6); 
  }
`
const ActionPanel = styled.div`
  padding: 16px 20px;
  background: rgb(255, 255, 255, 0.2);
  border-radius:20px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`

const Arrow = styled.img`
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
`

const FlexClmn = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`

const BadgeWrapper = styled.div`

	position: absolute;
    top: 50px;
    width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const Badge = styled.img`
	width: 100%;
	height: 100%;
`
const BadgeInner = styled.img`
	position: absolute;
    width: 74%;
    height: 65%;
`
const ModalContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 998;
	background: rgb(67, 74, 255);
	background: radial-gradient(
		circle at top,
		rgba(67, 74, 255, 1) 0%,
		rgba(31, 36, 155, 1) 33%,
		rgba(0, 0, 0, 1) 70%
	);
	overscroll-behavior: none;
`
const Wrapper = styled(motion.div)`
	width: 100%;
	height: calc(100% + 1px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	padding: 40% 20px 40px;
	position: relative;
	background: transparent;
	z-index: 999;
`
