import { css } from 'styled-components'

export const Fonts = css`
	@font-face {
		font-family: 'SF';
		src: url('/src/shared/assets/fonts/SF/SF-Pro-Display-Light.otf')
			format('otf');
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'SF';
		src: url('/src/shared/assets/fonts/SF/SF-Pro-Display-Regular.otf')
			format('otf');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'SF';
		src: url('/src/shared/assets/fonts/SF/SF-Pro-Display-Medium.otf')
			format('otf');
		font-weight: 500;
		font-style: normal;
	}

	@font-face {
		font-family: 'SF';
		src: url('/src/shared/assets/fonts/SF/SF-Pro-Display-Bold.otf')
			format('otf');
		font-weight: 600;
		font-style: normal;
	}
`