import {Route, Routes, useNavigate} from 'react-router-dom'
import { EarnPage } from '../../pages/earn'
import { AccountPage } from '../../pages/account'
import { FriendsPage } from '../../pages/friends'
import { useRecoilValue } from 'recoil'
import { tgState } from '../../entities/tg/model/tgState'
import {useEffect} from 'react'
import { AnimatePresence } from 'framer-motion'
import {LeaderBoard} from "../../pages/leaderboard";
import {useTelegramBackButton} from "../../features/navigations/hooks/useTelegramBackButton";
import {JoinClub} from "../../pages/joinClub/ui";
import {ClubInfo} from "../../pages/clubInfo/ui";

export const Router = () => {
	const tg = useRecoilValue(tgState)
	const navigate = useNavigate()
	useTelegramBackButton();

	useEffect(() => {
		navigate('/', { replace: true })
	}, [tg.isReady])

	return (
		<AnimatePresence mode="wait">
			<Routes>
				<Route path="/earn" element={<EarnPage />} />
				<Route path="/account" element={<AccountPage />} />
				<Route path="/leaderboard" element={<LeaderBoard />}/>
				<Route path="/friends" element={<FriendsPage />} />
				<Route path="/clubs" element={<JoinClub />} />
				<Route path="/club-info/:id" element={<ClubInfo />} />
			</Routes>
		</AnimatePresence>
	)
}