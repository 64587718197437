import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { tgState } from '../../../entities/tg/model/tgState'

export const useTelegramBackButton = () => {
	const navigate = useNavigate()
	const { tg } = useRecoilValue(tgState)
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/') {
			tg.BackButton.hide();
		} else {
			tg.BackButton.show()
		}
	}, [location]);

	useEffect(() => {
		if (tg) {
			tg.BackButton.onClick(() => {
				navigate(-1)
			})
			return () => {
				tg.BackButton.offClick()
				tg.BackButton.hide()
			}
		}
	}, [tg]);
}