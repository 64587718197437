import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import {

	MainText,
	PageIcon,
	SecondText,
	WolfBG,
	WrapperList
} from '../earn/ui'
import { useRecoilValue } from 'recoil'
import { friendsState } from '../../entities/friends/model/friendsState'
import styled from 'styled-components'
import { tgState } from '../../entities/tg/model/tgState'
import bgWolf from '../../shared/assets/bg/bgWolf.png'
import { motion } from 'framer-motion'
import {TopBadge} from "../leaderboard/ui";

export const FriendsPage = () => {
	const tg = useRecoilValue(tgState)
	const friends = useRecoilValue(friendsState)
	const memoizedFriends = useMemo(() => friends, [friends])
	const [gifLoaded, setGifLoaded] = useState(false)

	const gifRef = useRef(null)

	useEffect(() => {
		const img = new Image()
		img.src = '/assets/icon/friends.gif'
		img.onload = () => {
			setGifLoaded(true)
			if (gifRef.current) {
				gifRef.current.src = ''
				gifRef.current.src = '/assets/icon/friends.gif'
			}
		}
		if (gifRef.current) {
			gifRef.current.src = ''
			gifRef.current.src = '/assets/icon/friends.gif' // обновляем src для перезапуска gif
		}
	}, [])

	const inviteFriend = () => {
		const userId = tg['initData'].user['id']
		const text = `TON Walk. Check in to cash out. Join and walk with me.`
		tg.tg.openTelegramLink(
			`https://t.me/share/url?url=https://t.me/tonwalk_bot?start=${userId}&text=${text}`
		)
	}

	return (
		<ModalContainer>
			<Wrapper>
				<WolfBG src={bgWolf} alt="bgWolf" />
				<PageIcon
					src={gifLoaded ? '/assets/icon/friends.gif' : ''}
					alt={'anim'}
					ref={gifRef}
				/>
				<MainText>Friends</MainText>
				<SecondText style={{ textAlign: 'center' }}>
					Invite Friends and get 30 points for each other. <br /> And
					also 5% of the points received by each referral
				</SecondText>
				<WrapperList>
					<Body>
						{memoizedFriends.map((item, index) => (
							<Fragment key={index}>
								<Item>
									<FlexRow>
										<p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px',  width: '28px', textAlign: 'center'}}>{index + 1}</p>
										<TopBadge className={'default'}>
											<p>{item.username?.split('')[0]?.toUpperCase()}</p>
										</TopBadge>
										<FlexClmn>
											<p style={{fontSize: '17px', fontWeight: '400', lineHeight: '22px', color: '#FFF'}}>{item.username}</p>
											<p style={{fontSize: '15px', fontWeight: '400', lineHeight: '20px', color: '#C5C5C5', marginTop: '2px'}}>{item.totalScore}</p>
											<Divider style={{marginLeft: '0', marginTop:'16px' }}  className={index === memoizedFriends.length-1 && 'last' }/>
										</FlexClmn>
									</FlexRow>
								</Item>
							</Fragment>
						))}
					</Body>
				</WrapperList>
				<InviteButton onClick={() => inviteFriend()}>
					Invite friends
				</InviteButton>
			</Wrapper>
		</ModalContainer>
	)
}

const FlexClmn = styled.div`
	display: flex;
	flex-direction: column;
`
const Body = styled.div`
	width: 100%;
	border-radius: 20px;
	padding: 12px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(32px);
	-webkit-backdrop-filter: blur(32px);
	display: flex;
	flex-direction: column;
	gap: 16px;
`
const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`
const ModalContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 998;
	background: rgb(67, 74, 255);
	background: radial-gradient(
		circle at top,
		rgba(67, 74, 255, 1) 0%,
		rgba(31, 36, 155, 1) 33%,
		rgba(0, 0, 0, 1) 70%
	);
	overscroll-behavior: none;
`
const Wrapper = styled(motion.div)`
	width: 100%;
	height: calc(100% + 1px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	padding: 40% 20px 100px;
	position: relative;
	background: transparent;
	z-index: 999;
`

const InviteButton = styled.div`
	background-color: #007bff;
	color: #fff;
	padding: 14px 0;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	font-size: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	width: calc(100% - 50px);
	-webkit-tap-highlight-color: transparent;
	transition: all 0.3s ease;
	position: fixed;
	bottom: 34px;
`
const Item = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

 const Divider = styled.div`
	position: absolute;
	height: 0.5px;
	background: #94949469;
	border-radius: 0.25px;
	margin-left: 16px;
	&.last {
		background: transparent;
	}
`
