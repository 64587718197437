import React from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import { userBalance } from '../../../entities/balance/model/userBalance'
import coinIcon from '../../../shared/assets/icons/coins.svg'

export const Balance = () => {
	const balance = useRecoilValue(userBalance)
	return (
		<Wrapper>
			<img src={coinIcon} alt="coin" />
			{balance}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	border-radius: 20px;
	padding: 8px 12px;
	background: #212121;
	color: #fff;
	font-size: 17px;
	font-weight: 600;

	display: flex;
	align-items: center;
	gap: 4px;
	z-index: 2;
	box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
	img {
		width: 24px;
		height: 24px;
	}
`