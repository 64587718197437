import friendsPage from '../../assets/icons/friendsPage.svg'
import earnPage from '../../assets/icons/earnPage.svg'
import accountPage from '../../assets/icons/accountPage.svg'

export const Routes = [
	{
		id: 1,
		text: 'Earn',
		path: '/earn',
		icon: earnPage
	},
	{
		id: 2,
		text: 'Friends',
		path: '/friends',
		icon: friendsPage
	},
	{
		id: 3,
		text: 'Account',
		path: '/account',
		icon: accountPage
	}
]